import * as React from "react";
import Landing from "../components/landing";
import backgroundImage from "../images/studios/DSC_6587.jpg";

export default function IndexPage() {
  return (
    <Landing
      title="Elevate Your Game with Adaptive Music"
      subtitle="Save hours, improve the music &amp; immerse your players"
      currentPage="/"
      backgroundImage={backgroundImage}
    />
  );
}
